import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { StyledImage } from './StyledImage.styled';

import type { Locale } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { FC } from 'react';
import type { GatsbyImageGraphQLResponse, ImageNode } from './types';

interface Params {
  name: string;
  alt: string;
  locale?: Locale;
  loading?: 'eager' | 'lazy';
}

/**
 *
 * We ignore all images with `.inline` as they are assumed to be imported and inlined rather than src'd to.
 * WARNING: We ignore this error message:
 *   warn You can't use childImageSharp together with facebook.svg — use publicURL instead. The childImageSharp portion of the query in this file will return null: undefined
 *
 * We could split this into two queries: `images: allFile` and `svgs: allFile`…one with `childImageSharp` and one without.
 * However, it doesn't really matter, a null childImageSharp is what we expect.
 * The warning is just a warning…it shouldn't warn us in reality.
 */
const ImageQueries = graphql`
  query AllImages {
    images: allFile(filter: {
      sourceInstanceName: { eq: "images" },
      name: { regex : "/^(.(?!\\.inline))*$/" }
    }) {
      nodes {
        name
        relativePath
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

const findNode = (name: string) => (node: ImageNode) => {
  /*
   * Look against the full relative path, with extensions removed.
   * This is because `node.name` does not include the directory, so if we want to find `icons/dollar.png`, we have to use the relative path.
   */
  const nameWithDirectory = node.relativePath.replace(new RegExp(`\.${node.extension}$`), '');
  if (nameWithDirectory === name) return true;

  return false;
};

/**
 * useGatsbyImage
 * Returns a rendered image component.
 */
export const useGatsbyImage: FC<Params> = ({ name, alt, locale, loading = 'lazy' }) => {
  const { currentLocale } = useLocalizationContext();
  const { images }: GatsbyImageGraphQLResponse = useStaticQuery(ImageQueries);

  const localeId = (locale || currentLocale).id;

  const image = useMemo(() => {
    /*
     * Look for either a locale-specific image or an exact name match.
     * Eg. looks for `logo-au.png` then `logo.png` (just for reference; extensions are ignored)
     */
    const found =
      images.nodes.find(findNode(`${name}-${localeId}`)) || images.nodes.find(findNode(name));

    return found;
  }, [name, localeId, images.nodes]);

  return useMemo(() => {
    // prefer GatsbyImage!
    if (image?.childImageSharp?.gatsbyImageData) {
      return (
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={alt} loading={loading} />
      );
    }

    if (image?.publicURL) return <StyledImage src={image.publicURL} alt={alt} />;

    throw new Error(`Unable to find image '${name}'.`);
  }, [image, alt, name, loading]);
};
