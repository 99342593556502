import { BlogLink, ExternalLink, GatsbyLink } from 'components/Links';
import type { ReactNode } from 'react';

export type TeamMember =
  | 'Doug Morris'
  | 'Jessica Goodall'
  | 'Prashant Mohan'
  | 'Andrew Bird'
  | 'Tony Ryburn'
  | 'John Addis'
  | 'Nigel Allfrey'
  | 'Thorsten Böttger'
  | 'Ben Clendon';

export interface Profile {
  name: TeamMember;
  linkedin: string;
  twitter?: string;
  title: string;
  description: ReactNode;
}

export const executiveProfiles: Profile[] = [
  {
    name: 'Doug Morris',
    linkedin: 'https://au.linkedin.com/in/morrisdouglas',
    twitter: 'https://twitter.com/doug_sharesight',
    title: 'Chief Executive Officer',
    description: (
      <>
        Doug is the Chief Executive Officer of Sharesight and a leading voice in the Australasian
        FinTech and SaaS industries. He began his career with Morningstar, Inc. (NASDAQ: MORN) in
        Chicago, IL, where he served in a project management role within the investment management
        business. Doug then joined the Business Development Group and relocated to Sydney where he
        focused on growing adviser and institutional business lines. Doug then served as Product
        Manager for Morningstar Adviser Research Center, a platform serving financial advisers and
        then Global Product & Marketing Manager, Equity & Credit Research, overseeing international
        research distribution. Doug joined Sharesight as General Manager in 2013,{' '}
        <BlogLink to="sharesight-ceo-cto" title="Read the article">
          before being named CEO in May 2015.
        </BlogLink>
      </>
    ),
  },
  {
    name: 'Jessica Goodall',
    linkedin: 'https://linkedin.com/in/jessica-goodall-04055060/',
    title: 'Chief Customer Officer',
    description:
      'Jessica joined Sharesight in 2020. She has held various positions across finance and martech companies in the UK and throughout APAC respectively. Jessica joins Sharesight from her post as Customer Success Director APAC, at Partnerize, where she was responsible for everything from onboarding, enablement, retention and revenue growth for some of the world’s biggest brands, throughout Australia, Greater China and South Korea.',
  },
  {
    name: 'Prashant Mohan',
    linkedin: 'https://linkedin.com/in/prashant',
    twitter: 'https://twitter.com/prashantmrao',
    title: 'Chief Marketing Officer',
    description:
      'Prashant joined Sharesight in 2018. Before joining Sharesight, he was responsible for driving customer acquisitions and growth at leading fintech, Prospa. He has held various roles across finance and technology companies including running strategy and sales operations for Google in AUNZ. He holds an MBA from INSEAD business school in France and Singapore, and is also a graduate from BITS, Pilani.',
  },
  {
    name: 'Thorsten Böttger',
    linkedin: 'https://linkedin.com/in/tboettger',
    title: 'Head of Engineering',
    description:
      "Thorsten is a long-time software engineer and holds a master's degree in Computer Science. He joined Sharesight in 2012 when moving from Hamburg, Germany, to Wellington, New Zealand. He has career-long experience in the European startup scene and became Sharesight's technical lead shortly after returning back in 2015 from a two-year overseas stint. When he's not working you'll find him drinking coffee, playing football or taking care of his family.",
  },
  {
    name: 'Ben Clendon',
    linkedin: 'https://linkedin.com/in/ben-clendon-96525036',
    title: 'Head of Product',
    description:
      "Ben's first foray into investing was as a five year old receiving a parcel of shares from his dad. He still recalls the high quality physical share certificates his dad handed to him all those years ago. Since those days, Ben received a Bachelor in History & Criminology and later a Bachelor of Laws from Victoria University of Wellington. He spent nine years in both business and IT roles within the regulatory side of New Zealand's commercial fishing industry as well as three years travelling and living abroad. He joined Sharesight in 2015 within the product team and has enjoyed watching the company and product grow in an area he is so passionate about. When he's not at his desk, you'll likely find Ben in the mountains exploring with his family.",
  },
];

export const boardOfDirectors: Profile[] = [
  {
    name: 'Andrew Bird',
    linkedin: 'https://linkedin.com/pub/andrew-bird/b/b1a/221',
    title: 'Chairman',
    description: (
      <>
        Andrew joined Sharesight as an early investor in 2011. Previously, Andrew co-founded Aspect
        Huntley and grew the business to its current position as Australia’s leading provider of
        independent equities information and research. In July 2006 Aspect Huntley was sold to
        Morningstar, Inc (Nasdaq: MORN). Following the acquisition, Andrew served as CEO for
        Morningstar Australia and New Zealand from 2006-2010. Beyond serving on the Sharesight
        board, Andrew also invests in other growing Australian companies and is a director of{' '}
        <ExternalLink to="https://legalvision.com.au/" title="LegalVision">
          LegalVision
        </ExternalLink>{' '}
        and Allette Systems.
      </>
    ),
  },
  {
    name: 'John Addis',
    linkedin: 'https://linkedin.com/in/johnaddis',
    title: 'Director / Strategic Adviser',
    description:
      'John joined the Sharesight board in 2014 after consulting with Sharesight on its digital marketing strategy. He has over 35 years’ experience in marketing and advertising. In 1998 he co-founded The Intelligent Investor, a value investing-based research and funds management business which was sold to ASX-listed Australasian Wealth Investments in March 2014. In 2004 he became a founding partner of Private Media, publishers of Crikey.com.au and smartcompany.com.au and is one of Australia’s most knowledgeable experts in subscription marketing strategy and techniques.',
  },
  {
    name: 'Nigel Allfrey',
    linkedin: 'https://www.linkedin.com/in/nigel-allfrey-54777263/',
    title: 'Director',
    description:
      'Nigel was an early user of Sharesight, became an investor in 2015 and joined the Board of Directors in 2019. As a professional investor Nigel has several decades of business experience in the Australian and International Funds Management industry. He was Co-founder and CEO of Impact Investing Pty Ltd, a Funds Management FinTech company, which having achieved global success and recognition was acquired by BlackRock Inc (BLK:NYSE). Prior to that he held senior portfolio management positions with leading fund management firms BlackRock and Bankers Trust. Today Nigel is Principal of the Caana Group Family Office.',
  },
  {
    name: 'Tony Ryburn',
    linkedin: 'https://linkedin.com/pub/tony-ryburn/4/411/68a',
    title: 'Director / Co-Founder',
    description: (
      <>
        <GatsbyLink to="about-us">
          Tony co-founded Sharesight with his son Scott in 2007.
        </GatsbyLink>{' '}
        He has been a committed and enthusiastic investor in the Australian and New Zealand share
        markets for over 20 years, and he has over 30 years experience at the General
        Management/Director level in the finance sector including venture capital, development
        banking, finance companies and commercial banking. Tony has acted as an advisor to many
        private sector companies and government departments including Treasury, Ministry of Foreign
        Affairs and Trade and Ministry of Agriculture and Fisheries. Tony has a Bachelor of Commerce
        degree and has been a member of the Institute of Directors as well as of the Society of
        Accountants.
      </>
    ),
  },
];
