import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { SocialLink } from 'components/Links';

import { Box } from 'components/Box';
import { TitleContainer } from './TitleContainer.styled';
import { ImageWrapper } from './ImageWrapper.styled';

import type { TeamMember } from 'page-data/executiveTeam';
import type { FC } from 'react';

export const MemberTitle: FC<{ name: TeamMember; linkedin: string; twitter?: string }> = ({
  name,
  linkedin,
  twitter,
}) => {
  const linkedinImage = useGatsbyImage({
    name: 'team/linkedin',
    alt: `${name} on LinkedIn`,
  });

  const twitterImage = useGatsbyImage({
    name: 'team/twitter',
    alt: `${name} on Twitter`,
  });

  return (
    <Box marginBottom={4}>
      <TitleContainer>
        <h3>{name}</h3>
        <SocialLink size="xs" href={linkedin} title={`${name} on LinkedIn`}>
          <ImageWrapper>{linkedinImage}</ImageWrapper>
        </SocialLink>
        {twitter && (
          <SocialLink size="xs" href={twitter} title={`${name} on Twitter`}>
            <ImageWrapper> {twitterImage}</ImageWrapper>
          </SocialLink>
        )}
      </TitleContainer>
    </Box>
  );
};
